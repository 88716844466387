@font-face {
  font-family: 'Brandon';
  src: url('./brandon/BrandonGrotesque-Regular.woff2') format('woff2'),
       url('./brandon/BrandonGrotesque-Regular.woff') format('woff');
  font-style: normal;
}

body {
  font-family: 'CustomFont', sans-serif;
}
