@font-face {
    font-family: 'Brandon';
    src: local('Brandon'),
          url(./fonts/brandon/BrandonGrotesque-Regular.woff) format('woff'),
          url(./fonts/brandon/BrandonGrotesque-Regular.woff2) format('woff2');
  }

.buttonStyle {
background: linear-gradient(90deg, #C6A054, 0%, #F7931D,100%);
border-radius: 8px;
}
.active{
  background: #F7931D;
}
.desactive{
  background: #4a4a4a;
}
.textDegradade {
    font-size: 72px;
    background: -webkit-linear-gradient(left top, red, yellow);
    background: linear-gradient(to bottom right, red, yellow);
    -webkit-text-fill-color: transparent;

}
.optionMenu p{
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: inherit;
}
.optionText{
    font-family: 'Brandon Grotesque, sans-serif';
    font-weight: bold;
    font-style: normal;
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 5px;
}
.react-flow__attribution{
  display: none !important;
}
.countOut{
  font-size: 30px;
}

