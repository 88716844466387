.green-background {
  background-color: #0a8012;
  color: #fff;
}

@keyframes blink {
  0% {
    opacity: 0.7;
  }

  40% {
    opacity: 0.9;
  }

  50% {
    opacity: 1;
  }

  60% {
    opacity: 0.9;
  }

  100% {
    opacity: 0.7;
  }
}

.btn-assament {
  width: 30%;
  padding: 5px;
  margin: 15px;
}

.skeleton-blink {
  animation: blink 1s infinite;
}

.skeleton-blink article {
  min-height: 200px;
}

.skeleton-blink article.header,
.skeleton-blink article.footer {
  min-height: 70px;
}

footer.footer a {
  color: #17386d !important;
}

.react-flow__attribution {
  display: none !important;
}

.countOut {
  font-size: 30px;
}

.flex-teens {
  display: flex;
}

.gris-line {
  border-bottom: 1px solid #cecece;
  padding-bottom: 10px;
}

.titleQuestion2 {
  font-size: small;
}

.padding-none {
  padding: 2px;
  font-size: 14px;
  height: 1.5em;
}

.width-full {
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
}

.selectOption {
  width: 15%;
  text-align: center;
}

.titleActions {
  width: 95%;
}

.gridThemes {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3px;
  padding: 3px;
  width: 95%;
}

.gridThemes2 {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 3px;
  padding: 3px;
  width: 95%;
}

span.border-top-radius-right {
  border-top: 2px solid;
  padding-left: 3px;
}

span.border-top-radius-left {
  border-right: 2px solid;
  padding-right: 3px;
  border-radius: 50%;
}

.gridThemesNumbers {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 3px;
  padding: 3px;
  width: 20%;
}

.gridThemesMulti {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 3px;
  padding: 3px;
  width: 100%;
}

.gridThemescolorCode {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  padding: 1px;
  grid-gap: 1px;
  width: 95%;
}

.square {
  color: white;
  padding: 1px;
  width: 25px;
  height: 25px;
  text-align: center;
}

.Small {
  width: 85%;
}

.signo {
  font-size: 30px;
  font-weight: bold;
}

.grid {
  display: grid;
  grid-template-columns: auto;
  grid-gap: 3px;
  padding: 3px;
  position: absolute;
  width: 100%;
  bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 3px;
    padding: 3px;
    position: absolute;
    width: 95%;
    bottom: 20px;
  }

  .btn-assament {
    width: 50%;
  }

  .gridThemesNumbers {
    width: 80% !important;
  }

  .gridThemes2 {
    grid-template-columns: auto auto;
  }

  .divisionSigno {
    z-index: 1;
    width: 150px;
  }
}

@media only screen and (min-width: 992px) {
  .grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 3px;
    padding: 3px;
    position: absolute;
    width: 100%;
    bottom: 20px;
  }

  .gridThemesNumbers {
    width: 90% !important;
  }

  .divisionSigno {
    position: absolute;
    z-index: 1;
    left: 3.9%;
    margin-top: -5px;
  }
}

.negrita {
  font-weight: bold;
}

.input {
  border-color: "#41D2F1";
  color: "#000";
}

.Missterm {
  width: 50%;
  font-size: 20px;
}

.poligono svg {
  filter: drop-shadow(0 0.5rem 0.5rem rgba(0, 0, 0, 0.5));
}

.ocultarMapa {
  position: absolute;
  bottom: 1000px;
  display: block !important;
  visibility: hidden;
}

.borderBottom {
  border-bottom: 3px solid;
}

.calc-grid {
  width: 30%;
  border: 1px solid #ffe08a;
}

.ops {
  display: flex;
  z-index: 4;
}

.ops button {
  border: 0.5px solid #ffe08a;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffe08a;
  width: 20%;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  z-index: 4;
}

.ops button:hover {
  background-color: #ededed;
}

.dig button {
  flex: 1 1 33.333%;
  min-width: 33.333333%;
  background-color: #ededed;
  height: 40px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: 0.5px solid #ededed;
}

.dig button:hover {
  background-color: #ffe08a;
}

.output {
  min-height: 49px;
  text-align: right;
  font-size: 20px;
  display: flex;
  opacity: 46.5%;
  background-color: #c5c5c5;
  border: 1px solid black;
  flex-direction: column;
  z-index: 2;
  box-sizing: content-box;
}

.preRes {
  display: none;
}

.output textarea {
  width: 100%;
  border: none;
  text-align: right;
  padding-right: 4px;
  resize: none;
  font-size: 16px;
}

.imagesIcon {
  width: 50% !important;
}

.imagesIconL {
  width: 50%;
  height: 60%;
}

.images img {
  width: 70% !important;
}

.eqBorder {
  border: 1px solid #ffe08a;
}

.textEq {
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #ffe08a;
  margin: 10px;
}

.textEq2 {
  font-size: 18px;
  text-align: center;
}

.btninactivo {
  width: 100%;
  font-size: 18px;
  background-color: #c5c5c5 !important;
}

.btnactivo {
  background-color: red !important;
  color:white !important;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}

.layoutflow {
  flex-grow: 1;
  position: relative;
  height: 100%;
}

.layoutflow .controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  font-size: 12px;
}

.layoutflow .controls button:first-child {
  margin-right: 10px;
}

.react-flow__panel {
  bottom: 60px !important;
}

.react-flow__edge .animated {
  background-color: #cecece;
}

.react-flow__edge .selected {
  background-color: #cecece;
}

.divisionSignoA {
  z-index: 2;
}

.divisionSignoB {
  z-index: 4;
  padding-left: 18px;
  background-color: white;
}

.contentEqv {
  flex-wrap: wrap;
  flex-direction: unset;
  align-items: center;
  justify-content: center;
}

.botones div {
  margin: 0px;
  width: 25%;
  height: 45px;
  text-align: center;
  padding: 5px;
  font-style: normal;
  font-weight: 390;
  font-size: 22px;
  color:#000000;
}
.styleBotton{
  z-index: 3;
}
.teclado-virtual {
  z-index: 5;
  position: fixed;
  bottom: 0;
  /* left: 0; */
  width: 100%;
  background: rgba(245, 247, 246,1);
}
.padding-100{
  padding-bottom: 100px;
}

.headerIncorrect {
  background: #e9e9e9;
  padding: 4px;
}

.tableResponse {
  width: 100%;
}

.correctBorder {
  border-bottom: 2px solid #88b454;
  background: #f3f7ee;
}

.incorrectBorder {
  border-bottom: 2px solid #df4439;
  background: #f3f7ee;
}

.responseSolution,
.titleSolution {
  font-style: normal;
  font-weight: 390;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  text-align: center;

  /* text */

  color: #151511;
}

.inresponseSolution {
  color: #df4439 !important;
}

.responseCorrectBorder {
  background: #ffffff;
  /* gray */
  text-align: center;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #ababab;
}

.fondoGood {
  background: #b88f3e1a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  font-style: normal;
  font-weight: 420;
  font-size: 50px;
  line-height: 71px;
}

.btnTeclado {
  border:none!important;
  cursor: pointer;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  border-bottom: 1px solid #ABABAB !important;
  border-radius: 4px;
  font-family: 'Brandon';
  color:#000000!important;
}

.btnTeclado:hover {

  border-bottom: 1px solid #E0E2E1 !important;
}

.btnTecladoInactive{
  background: #E0E2E1 !important;
  border-bottom: 1px solid #ABABAB !important;
}
.btnClear{
  background: #F4E8D6 !important;
  border-bottom: 1px solid #ABABAB !important;
}
.btnSubmit{
  background: linear-gradient(90deg, #B88F3E 0%, #F7931D 94.93%) !important;
  color: #ffffff !important;
}
.btnArrow{
  background: #F4E8D6 !important;
border-bottom: 1px solid #ABABAB !important;
}

.imageDiv {
  width: 200px;
  position: absolute;
  margin-top: -5px;
  z-index: 1;
}
.imageDivFor3Imput{
  width: 125px;
    position: absolute;
    margin-top: -63px;
    z-index: 1;
    margin-left: -12px;
}
.imageDivForResponse{
  width: 59px;
    position: absolute;
    margin-top: -40px;
    z-index: 1;
    margin-left: -12px;

}

.react-flow {
  height: 85vh !important;
  overflow: scroll !important;
  scroll-padding: 0;
}

.repuestaCorrecta {
  background-color: #0a8012;
  color: white;
  border-radius: 10px;
  padding: 2px;
}

.repuestaBlue {
  background-color: blue;
  color: white;
  border-radius: 10px;
  padding: 2px;
}

.repuestaInCorrecta {
  background-color: #ff0000;
  color: white;
  border-radius: 10px;
  padding: 2px;
}

.repuestaNormal {
  background-color: #fff;
  color: black;
}

.colorSquare {
  flex-direction: row;
  flex-wrap: wrap;
  width: 330px !important;
  justify-content: center;
  display: flex;
  padding-left: 3px;
  padding-right: 3px;
  align-content: flex-start;
}

.colorLine {
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  display: flex;
  padding-left: 3px;
  padding-right: 3px;
  align-content: flex-start;
  height: 200px;
}

.colorLine div {
  height: 20px;
  width: 20px;
}

.activoSquare {
  border: 1px solid white;
  padding: 0px;
  width: 15px;
  height: 15px;
  background: #ffe08a;
}

.inactivoSquare {
  border: 1px solid white;
  padding: 0px;
  width: 15px;
  height: 15px;
  background: #4a4a4a;
}

.blockAction {
  width: 90%;
  flex-direction: column;
}
.gripButton {

  height: 25px;
  background-color: blue;
  color: white;
  font-size: 10pt;
  font-weight: bold;
  text-align: center;
}

.gripButtonInactivo {

  height: 25px;
  background-color: red;
  color: white;
  font-size: 10pt;
  font-weight: bold;
  text-align: center;
}
.gripButtonSinColor {
  width: 25px;
  height: 25px;
  background-color: grey;
}
.textNormal {
  color: #151511;
  font-weight: bold;
}
.textCenter {
  color: #151511;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}
.textIncorrect , .textInCorrect{
  color: red !important;
  font-weight: bold;
}
.textCorrect {
  color: #0a8012;
  font-weight: bold;
}

.react-ruler-wrapper .ruler-container {
  margin-top: 60px;
}
.react-ruler-wrapper .ruler-wrapper {
  position: relative;
}
.react-ruler-wrapper .ruler-list {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #dee3e6;
}
.react-ruler-wrapper .ruler-list .line {
  height: 6px;
  border-left: 1px solid #dee3e6;
}
.react-ruler-wrapper .ruler-list .rule-mark .line-text {
  position: absolute;
  top: -22px;
  margin-left: -4px;
  font-size: 10px;
  color: #a3afb7;
}
.react-ruler-wrapper .ruler-list .rule-mark .line {
  border-left: 1px solid #dee3e6;
  height: 20px;
}
.react-ruler-wrapper .ruler-underline {
  width: 296px;
  height: 1px;
  background-color: #dee3e6;
}
.react-ruler-wrapper .ruler-drag {
  position: absolute;
  left: 0;
  bottom: 46px;
  height: 100%;
  width: 100%;
  z-index: 100;
  transform-origin: left;
}
.react-ruler-wrapper .ruler-drag .ruler-point {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.react-ruler-wrapper .ruler-drag .ruler-point .point {
  position: absolute;
  padding: 0px 9px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #2acd8f;
  color: #ffffff;
  left: -18px;
  margin-bottom: 5px;
  line-height: 24px;
}
.react-ruler-wrapper .ruler-drag .ruler-point .ruler-line {
  width: 2px;
  height: 38px;
  background-color: #2acd8f;
  cursor: pointer;
  margin-top: 29px;
}
.prevBtn {
  background: #f1e9e9 !important;
  color: #151511;
}
.nextBtn {
  background: #f1e9e9 !important;
  color: #151511;
}
.colorNumber {
  width: 25px;
  height: 25px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: white;
}
.text-center {
  text-align: center;
}

.borderNoSelect {
  border: 1px solid #f1e9e9 !important;
  padding: 3px;
  margin: 3px;
  background: none;
  width: 100%;
}

.borderSelect {
  border: 1px solid #0a8012 !important;
  background: #cecece !important;
  padding: 3px;
  margin: 3px;
  background: none;
  width: 100%;
}
.borderAnswer {
  padding: 3px;
  margin: 3px;
}

.signoMenor {
  font-size: 20px;
  font-weight: bold;
}
.signoSmall {
  font-size: 14px;
  font-weight: 400;
}
.rulerMarcada {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  position: relative;
  margin: 0px;
  height: 1em;
  padding: 0;
  color: #ddd;

  counter-reset: cm;
  width: max-content;
  font: 0.85em Arial;
}
.rulerMarcada .pintar {
  background-color: red;
}
.rulerMarcada li {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  color: transparent;
  width: 0.73cm;
  height: 5px;
  transform: translate(0, -0.75em);
  box-sizing: border-box;
}
.ruler {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  position: relative;
  margin: 0px;
  height: 2em;
  padding: 0;
  color: #ddd;
  outline: 2px currentColor solid;
  outline-offset: -2px;

  counter-reset: cm;
  width: max-content;
  font: 0.85em Arial;
}

.ruler::before {
  display: block;
  width: max-content;
  content: "0";
  position: absolute;
  bottom: 100%;
  left: 1px;
  transform: translate(-50%, -1em);
}

.ruler li::after {
  content: counter(cm);
  counter-increment: cm;
  display: block;
  width: max-content;
  overflow: visible;
  color: #ddd;
  text-align: right;
  transform: translate(50%, -1.5em);
}
/*
 .ruler li:nth-child(2n - 1)::after {
    color: transparent;
 }
*/

.ruler li {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  color: transparent;
  width: 0.73cm;
  height: calc(2px + 1.5em);
  transform: translate(0, -0.75em);
  box-sizing: border-box;
  border-left: 2px #ddd solid;
}

.ruler li:last-child {
  border-right: 2px #ddd solid;
}

.padding {
  height: 20px;
}
.rulerMarcadaTwo{
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  position: relative;
  margin: 0px;
  height: 1em;
  padding: 0;
  color: #ddd;

  counter-reset: cm;
  width: max-content;
  font: 0.85em Arial;
}


.rulerMarcadaTwo .pintar {
  background-color: red;
}
.rulerMarcadaTwo li {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  color: transparent;
  width: 0.2cm;
  height: 5px;
  transform: translate(0, -0.75em);
  box-sizing: border-box;
}
.blue{
  border-bottom: 4px solid blue;
}
.red{
  border-bottom: 4px solid red;
}
.uppercase{
  text-transform: uppercase;
}
.primerElement {
  width:100%;
  border: 1px solid #000000;
}
.primerElement td{
  height: 80px;
  width: 5px;
}

svg.clock {
	stroke: #666;
	stroke-width: 2 !important;
	fill: none;
}

svg.clock text {
	stroke: none;
}

svg.clock.path {
  transition: all 0.1s ease-in-out;
}

text.middle {
  dominant-baseline: middle;
  text-anchor: middle;
}

.scrollingWebKit {
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}
.scorlling::-webkit-scrollbar,.scorlling2::-webkit-scrollbar,.scrollingWebKit::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.scorlling::-webkit-scrollbar-track,.scorlling2::-webkit-scrollbar-track,.scrollingWebKit::-webkit-scrollbar-track  {
  background-color: darkgrey;
}
.scorlling::-webkit-scrollbar-thumb,.scorlling2::-webkit-scrollbar-thumb,.scrollingWebKit::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scorlling {
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  height: 37vh;
  line-height: 1em;
  overflow-x: hidden;
  overflow-y: scroll;
}
.scorlling2{
  scrollbar-color: #6969dd #e0e0e0;
  scrollbar-width: thin;
  height: 45vh;
  line-height: 1em;
  overflow-x: hidden;
  overflow-y: scroll;
}
.relativePosition{
  position: absolute;
  width: 80px;
  z-index: 3;
}
.relativePositionOptions{
  padding-top: 30px;
}


.StripeElement {
  border: #B88F3E 1px solid;
  padding: 10px;
  border-radius: 5px;
}

/* .custom-card-number .StripeElement .__PrivateStripeElement {
  border: 2px solid #ff0000 !important  ;
  padding: 10px;
  border-radius: 4px;

} */

/* custom scroll */
.custom-scrollbars__container {
  background-color: white;
  border: 1px solid #333;
  border-radius: 12px;
  display: grid;
  height: 100%;
  grid-template: auto / 1fr 50px;
  overflow: hidden;
  position: relative;
}

.custom-scrollbars__content {
  height: 90vh;
  -ms-overflow-style: none;
  overflow: auto;
  padding: 1rem;
  scrollbar-width: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.custom-scrollbars__scrollbar {
  display: grid;
  gap: 1rem;
  grid-auto-flow: row;
  grid-template: auto 1fr auto / 1fr;
  padding: .3rem;
  place-items: center;
}

.custom-scrollbars__track-and-thumb {
  display: block;
  height: 100%;
  position: relative;
  width: 16px;
}

.custom-scrollbars__track {
  background-color: #ccc;
  border-radius: 12px;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 16px;
}

.custom-scrollbars__thumb {
  border-radius: 12px;
  background-color: #333;
  position: absolute;
  width: 16px;
}

/* end custom scroll */
